<template>
	<div class="trustCompaniesContainer" ref="trustCompaniesContainer">
		<div class="container">
			<div class="blockLeftTitle">
				<div class="blockLeftTitle__left">
					<div class="mainTitle2">
						<p class="h2 animationBlockFadeUp" v-html="content.title" v-if="content.title"></p>
						<p class="mainTitle2--desc color-gray animationBlockFadeUp" v-html="content.desc" v-if="content.desc"></p>
						<a href="#feedBackForm" class="link animationBlockFadeUp mt32">Стать клиентом</a>
					</div>
				</div>
				<div class="blockLeftTitle__right">
					<div class="trustCompanies">
						<div class="trustCompanies__item animationBlockFadeUp" v-for="(item, index) in content.items" :class="item.additionalClass">
							<img :src="item.logo">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/TrustCompanies'
</style>

<script>
	export default {
		props: ['content'],
		data: () => ({
		}),
		mounted(){
		},
		methods: {
		}
	}
</script>
